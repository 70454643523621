<template>
  <div v-show="isProgress">
    <div class="modal-cover"></div>
    <img src="@/assets/images/progress.gif" alt="progress" class="progress-icon">
  </div>
</template>

<script>
export default {
  name: "Progress",
  props: {
    isProgress: Boolean,
  },
}
</script>

<style scoped>
.progress-icon{

}
</style>
