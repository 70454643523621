<template>
  <div class="main-frame">
    <div v-if="isShow" class="main-container">
      <div class="payment">
        <div class="title">구매 상품</div>
        <div class="item-data">
          <div class="item-img"><img :src=itemData.thumbnail></div>
          <div class="item-text">
            <p class="subCategory">{{ itemData.subCategory }}</p>
            <h3>{{ itemData.title }}</h3>
            <p class="seller">판매자 <strong>{{ itemData.seller }}</strong></p>
          </div>
        </div>
        <div class="line"/>
        <div class="radio-check">
          <h4>결재방법</h4>
          <div class="radio-line">
            <mdb-form-inline>
              <mdb-input class="check" type="radio" id="payment-1" name="groupRadios2" radioValue="1" v-model="radioValue" label="무통장 입금" disabled/>
              <mdb-input class="check" type="radio" id="payment-2" name="groupRadios2" radioValue="2" v-model="radioValue" label="실시간 계좌이체" disabled/>
              <mdb-input class="check" type="radio" id="payment-3" name="groupRadios2" radioValue="3" v-model="radioValue" label="신용카드" disabled/>
              <mdb-input class="check" type="radio" id="payment-4" name="groupRadios2" radioValue="4" v-model="radioValue" label="휴대폰" disabled/>
            </mdb-form-inline>
          </div>
        </div>
        <div class="payment-info">
          <h4>결제 정보</h4>
          <div v-if="!isPayment" class="warning">결제가 완료되지 않은 상품입니다.</div>
          <div class="bank">은행: {{ paymentData.bank }}</div>
          <div>송금자: {{ paymentData.senderName }}</div>
          <div v-if="!isPayment">결제 시간: {{ timeToPay }} 까지</div>
          <div v-if="isTimeToPay" class="warning">결제시간이 초과되었습니다.</div>
          <div class="line my-2"/>
          <div>현금영수증: {{ isReceipt }}</div>
          <div v-if="isReceipt === 'O'">
            <div>분류: {{ cashReceipt.type }}</div>
            <div>전화번호: {{ cashReceipt.mobile }}</div>
          </div>
        </div>
        <div class="btn-box">
          <button class="back-btn custom-btn" @click="$router.go(-1)">뒤로</button>
          <button v-if="!isPayment && !isTimeToPay && $route.path.includes('/master')" class="custom-btn" @click="paymentCompleted">결제완료 체크</button>
          <button class="del-btn custom-btn" @click="delReceipt">
            <p v-if="!isPayment && $route.path.includes('/user')">구매 취소</p>
            <p v-else>삭제</p>
          </button>
        </div>
      </div>
      <div class="purchase-data">
        <div class="purchase-text">주문금액 <span>{{ itemData.price }}원</span></div>
        <div class="purchase-text">수수료 <span>{{ fee.toLocaleString() }}원</span></div>
        <div class="purchase-text">쿠폰적용 <span>-</span></div>
        <div class="purchase-text">할인금액 <span>-</span></div>
        <div class="line"/>
        <div class="receipt">
          <div class="total">총 결제금액 <span>{{ totalPrice }}원</span></div>
        </div>
      </div>
    </div>
    <Progress :isProgress=isProgress></Progress>
  </div>
</template>

<script>
import {FieldValue, firestore, Timestamp} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import {mdbFormInline, mdbInput} from "mdbvue";
import Progress from "@/components/Progress.vue";

export default {
  name: "MyPaymentDetail",
  components: {
    mdbInput,
    mdbFormInline, Progress
  },
  data() {
    return {
      uid: this.$route.params.uid,
      itid: this.$route.params.itid,
      isShow: false,
      isProgress: false,
      isPayment: false,
      isTimeToPay: false,
      itemData: {},
      paymentData: {},
      cashReceipt: {},
      timeToPay: '',
      totalPrice: 0,
      price: 0,
      fee: 5000,
      radioValue: '1',
      isReceipt: '',
    }
  },
  mounted() {
    this.init()
  },
  watch: {},
  methods: {
    async init() {
      const self = this;
      self.getItem()
      await self.getReceipt()
      await self.checkTimeToPay()
      self.isShow = true
    },
    getItem() {
      const self = this;
      firestore.collection('item')
          .doc(self.uid)
          .get()
          .then((snapshot) => {
            self.itemData = snapshot.data()
            self.price = parseInt(self.itemData.price)
            self.totalPrice = (self.price + self.fee).toLocaleString()

            const date = new Date(snapshot.data().lastDate.seconds * 1000);
            self.itemData.lastDate = getDate(date)
            self.itemData.price = self.itemData.price.toLocaleString()
            self.itemData['docId'] = snapshot.id
          })
    },
    async getReceipt() {
      const self = this;
      await firestore.collection('receipt')
          .doc(self.itid)
          .get()
          .then((snapshot) => {
            const data = snapshot.data()
            self.timeToPay = data.timeToPay
            self.isPayment = data.isPayment
            self.paymentData = data.paymentData
            self.cashReceipt = data.paymentData.cashReceipt
            if (self.cashReceipt.isReceipt) {
              self.isReceipt = 'O'
              if (self.cashReceipt.type === 'group') self.cashReceipt.type = '지출증빙용'
              else self.cashReceipt.type = '소득공제용'
            } else self.isReceipt = 'X'
          })
    },
    checkTimeToPay() {
      const self = this;
      if (self.isPayment) return
      const getDateTime = new Date(self.timeToPay);
      const now = new Date();

      if (getDateTime < now) self.isTimeToPay = true
      else self.isTimeToPay = false
    },

    paymentCompleted() {
      const self = this;
      const update = {
        isPayment: true,
        timeToPay: FieldValue.delete(),
        regDate: Timestamp.fromDate(new Date())
      }

      self.$swal.fire({
        title: '결제완료 하시겠습니까?',
        text: "확인을 누르시면 완료됩니다",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '확인',
        cancelButtonText: '취소'
      }).then(async (result) => {
        if (result.isConfirmed) {
          self.isProgress = true
          firestore.collection('receipt')
              .doc(self.itid)
              .set(update, {merge: true})
              .then(() => {
                self.isProgress = false
                self.$swal.fire('결제완료!', '결제가 완료 되었습니다.', '성공')
                    .then(async () => {
                      self.$router.go(-1)
                    })
              })
        }
      })
    },
    delReceipt() {
      const self = this;
      let alertTitle = '결제정보를 삭제'
      if (self.$route.path.includes('/user') && !self.isPayment)
        alertTitle = '구매 취소를'
      self.$swal.fire({
        title: alertTitle + '하시겠습니까?',
        text: "확인을 누르시면 삭제됩니다",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '확인',
        cancelButtonText: '취소'
      }).then(async (result) => {
        if (result.isConfirmed) {
          self.isProgress = true
          await firestore.collection('receipt')
              .doc(self.itid)
              .delete()
              .then(() => {
                self.isProgress = false

                if (this.$route.path.includes('/user')) {
                  self.$swal.fire('구매 취소 완료!', '요청이 정상적으로 처리 되었습니다.', '성공')
                      .then(async () => {
                        self.$router.go(-1)
                      })
                } else {
                  self.$swal.fire('삭제완료!', '결제정보가 삭제 되었습니다.', '성공')
                      .then(async () => {
                        self.$router.go(-1)
                      })
                }
              })
        }
      })
    }
  }
}
</script>

<style scoped>

.main-frame {
  width: 1224px;
  margin: 0 auto;
}

.main-frame .main-container {
  display: flex;
  padding: 60px 16px;
}

.main-frame .main-container .payment {
  width: 832px;
  height: 100%;
  margin-right: 60px;
}

.main-frame .main-container .payment .title {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  margin-bottom: 10px;
}

.main-container .payment .item-data {
  height: 134px;
  display: flex;
  margin-bottom: 20px;
}

.main-container .payment .item-data .item-img {
  width: 200px;
  height: 134px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #A1A1AA;
  overflow: hidden;
  border: 1px solid #E4E4E7;
}

.main-container .payment .item-data img {
  width: 100%;
  height: 100%;
}

.main-container .payment .item-text {
  font-weight: 500;
  width: 502px;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 20px;
}

.main-container .payment .item-text .subCategory {
  color: #71717A;
  font-size: 14px;
  letter-spacing: -0.28px;
  margin-bottom: 10px;
}

.main-container .payment .item-text h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: left;
}

.main-container .payment .item-text .seller {
  letter-spacing: -0.32px;
}

.main-container .payment .item-text .seller strong {
  font-weight: 700;
}

.main-container .payment .price img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.main-container .payment .radio-check {
  margin: 20px 0;
}

.main-container .payment h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: start;
  margin-bottom: 10px;
}

.main-container .payment .payment-info h4 {
  margin: 0;
}

.main-container .payment .payment-info .warning {
  font-size: 15px;
  font-weight: 600;
  color: #EF4444;
}

.main-container .payment .payment-info .bank {
  margin-top: 10px;
}

.main-container .payment .radio-check .radio-line {
  height: 20px;
  display: flex;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.main-container .payment .radio-check .radio-line .check {
}


.radio-line::v-deep(.form-check-input[type="radio"]:checked+label),
.radio-line::v-deep(.form-check-input[type="radio"]:not(:checked)+label) {
  padding-left: 22px
}

.radio-line::v-deep(.form-check-input[type="radio"]:checked+label:after),
.radio-line::v-deep(.form-check-input[type="radio"]:checked+label:after) {
  background: #ff8a00;
  border: #ff8a00;
}

.main-container .payment .radio-check .radio-line ::v-deep(.form-check-label.mr-5) {
  padding-left: 24px;
}

.main-container .payment .payment-info div {
  text-align: start;
  font-weight: 500;
}

.main-container .payment .btn-box {
  margin-top: 10px;
  text-align: end;
}

.main-container .payment .btn-box .back-btn {
  background: #ffffff;
  border: 2px solid #ff8a00;
  color: #ff8a00;
  width: 100px;
  margin-right: 10px;
}

.main-container .payment .btn-box .del-btn {
  background: #EF4444;
  width: 100px;
  margin-left: 10px;
}

.main-frame .main-container .purchase-data {
  display: grid;
  width: 300px;
  height: 300px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: #FAFAFA;
  gap: 10px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #71717A;
}

.main-container .purchase-data .total,
.main-container .purchase-data .purchase-text {
  display: flex;
  justify-content: space-between;
}

.line {
  height: 1px;
  width: 100%;
  background: #E4E4E7;
}

.purchase-data .receipt {
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #000000;
}

.purchase-data .receipt .total {
  line-height: 23px;
}

</style>